import { useEffect } from "react";
import { NavLink } from "react-router-dom";

export default function Leder() {

  useEffect(() => {
    document.title = "Leder | Hälsinglands Multisportklubb";
    document.getElementById("main")?.scrollIntoView(  { behavior: 'smooth' })
  }, []);

  return (
    <>
      <article id="mainContent">
        <h1>Cykel- & vandringsleder</h1>

        <figure>
          <a href="/images/ledinformation.pdf" target="_blank"><img src="/images/ledinformation.jpg" alt="Ledkarta, Stormorshäll" /></a>
          <figcaption>Ledkarta, Stormorshäll. Klicka på bilden för en högupplöst PDF-fil</figcaption>
        </figure>

        <p>
          För närvarande finns 5 stycken skyltade leder som alla
          utgår från Stormorshällstugan. Skyltarna är fyrkantiga med
          olika färgkodning och färdriktining är markerad med en
          pil i reflexmaterial som gör att den syns väl även på kvällen
          med cykel- och pannlampa. Lederna varierar i längd, kupering och svårighetsgrad för
          att passa så många som möjligt.
        </p>
        <p>Utöver dessa finns även en reflexbana för löpning.</p>

        <h4 className="led blue">Lugnetleden - 400M (SPRINT)</h4>
        <p>
          En kort sprintbana i anslutning till Stormorshällsstugan.
          Passar utmärkt för intervallträning eller de allra minsta.
        </p>

        <h4 className="led green">Vadslingan - 7,5 KM</h4>
        <p>
          Vadslingan är vår enklaste led och tar dig genom natursköna Lugnesjöns naturreservat. Terrängcykeln behövs ej för denna led.
        </p>

        <h4 className="led red">Björnstigen - 3 KM</h4>
        <p>
          Vår röda slinga går i huvudsak via ett gammalt skidspår och innehåller ett par tekniska partier samt lite myr.
        </p>

        <h4 className="led black">Stormorshällslingan - 4,5 KM</h4>
        <p>
          En lite tuffare led med ett par rejäla backar och några tekniska partier. Passar både motionären som vill utmana och den mer erfarne.
        </p>

        <h4 className="led orange">Stormorstrailen - 8 KM</h4>
        <p>
          Här får du lite av allt. En kuperad led som passerar bland annat Lugnesjön, rullstensåsen,
          grillkåtan och själva Stormorshällen. Perfekt för trailrunning och vandring. Mycket teknisk cykling.
          Det är denna led som används i <NavLink to="/stormorstrailen">motionsloppet med samma namn</NavLink>.
        </p>

        <h4 className="led reflex">Reflexbana</h4>
        <p>
          Sedan hösten 2019 finns även 2 reflexbanor markerade. Banorna delar på sig efter 2 km där den långa sedan ansluter till den kortare igen.
          Banorna är markerade med runda, gula reflexer cirka 5 cm breda.
        </p>

        <h4>Trivselregler</h4>
        <ul>
          <li>Visa hänsyn till varandra.</li>
          <li>Cyklister har väjningsplikt och ska anpassa farten vid möten med andra motionärer.</li>
          <li>Hundägare bör alltid ha kunden kopplad.</li>
          <li>Lämna inte skräpet i naturen. Har du fått med dig det ut i skogen kan du också ta med det hem igen.</li>
          <li>Vissa av lederna passerar elljusspår som ej bör beträdas vintertid då skidspåren är preparerade.</li>
          <li>Var uppmärksam på underlaget och vädret. Rötter och stenar kan bli väldigt hala.</li>
        </ul>
      </article>
    </>
  )
}